<template>
  <div class="content">
    <div class="row">
      <div class="col">
        <h4>Overview</h4>
        <div>
          <p>Coupons are implemented the same way they are on WooCommerce. As they are very complex, some of the functionalities are not implemented yet, or never will because of some technical limitations.</p>
          <p>Please see the notes to confirm the functionality you added to the coupon is implemented:</p>

          <table class="table">
            <tbody>
              <tr>
                <th>*1</th>
                <td>Free Gift coupons are not implemented, as they are not documented in WC documentation</td>
              </tr>
              <tr>
                <th>*2</th>
                <td>We only allow 1 coupon at time in any case: this option is ignored by Vave</td>
              </tr>
              <tr>
                <th>*3&nbsp;*5</th>
                <td>When the order is created during the checkout, the coupon is not used yet (is only marked as used after the order is paid), so a user can potentially open 2+ tabs, place 2+ orders with the same coupon and it will work. What happens is that we will push the Order to WC that will create it with status payment_pending. It will then synchronise back to Vave with status Processing, but the customer has actually paid for all orders. If possible, avoid using this option.</td>
              </tr>
              <tr>
                <th>*4</th>
                <td>The discount is applied to the most expensive items first, so be careful with percentage discounts.</td>
              </tr>
            </tbody>
          </table>

          <div class="row">
            <div class="col-auto">
              <div class style="border: 1px solid black;">
                <img class="coupon-image" :src="images.coupons" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      images: {
        coupons: require("./../assets/images/coupons.png"),
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.coupon-image {
  max-width: 1200px;
  width: 100%;
}
</style>